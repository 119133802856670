<template>
  <a-card title="拣货记录详情">
    <!-- <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
      <a-icon type="printer" />打印</a-button
    > -->
    <a-button
      slot="extra"
      type="primary"
      ghost
      @click="
        () => {
          this.$router.go(-1);
        }
      "
    >
      <a-icon type="left" />返回</a-button
    >
    <section id="printContent">
      <a-spin :spinning="spinning">
        <a-descriptions bordered>
          <a-descriptions-item label="拣货/波次拣货单号">
            {{ detailInfo.picking_order_number }}
          </a-descriptions-item>
          <a-descriptions-item label="仓库">
            {{ detailInfo.warehouse_name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建人">
            {{ detailInfo.creator_name }}
          </a-descriptions-item>
          <a-descriptions-item label="创建时间">
            {{ detailInfo.create_time }}
          </a-descriptions-item>
          <a-descriptions-item label="仓库编号">
            {{ detailInfo.warehouse_number }}
          </a-descriptions-item>
          <a-descriptions-item label="总数量">
            {{ detailInfo.total_quantity }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>

      <div style="margin-top: 16px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="materialItems"
          :loading="materialLoading"
          :pagination="false"
        >
          <template slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <a-space>
              <img
                v-for="item in record.picking_record_image_items"
                :src="item.file"
                :key="item.id"
                width="100"
                height="100"
                @click="preview(item.file)"
                style="cursor: pointer"
              />
            </a-space>
          </template>
        </a-table>
      </div>
    </section>

    <a-modal :visible="previewVisible" title="图片" :footer="null" @cancel="previewVisible = false">
      <img alt="image" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-card>
</template>

<script>
import { pickingRecordDetail, pickingRecordMaterials } from "@/api/stockOut";

export default {
  name: "PickingRecordDetail",
  components: {},
  data() {
    return {
      description: "详情",
      spinning: false,
      materialLoading: false,
      visible: false,
      detailInfo: {},
      materialItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "名称",
          dataIndex: "material_name",
          key: "material_name",
          width: 300
        },
        {
          title: "产品英文名称",
          dataIndex: "material_english_name",
          key: "material_english_name",
          width: 300
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: "数量",
          dataIndex: "total_quantity",
          key: "total_quantity",
        },
        {
          title: "库位",
          dataIndex: "location_number",
          key: "location_number",
        },
        {
          title: "批次",
          dataIndex: "batch_number",
          key: "batch_number",
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: "状态",
          dataIndex: "status_display",
        },
      ],
      previewVisible: false,
      previewImage: "",
    };
  },
  computed: {},
  methods: {
    initData() {
      this.getData(this.$route.query.id);
    },
    getData(id) {
      this.spinning = true;
      this.materialLoading = true;
      pickingRecordDetail({ id })
        .then((data) => {
          this.detailInfo = data;
        })
        .finally(() => {
          this.spinning = false;
        });
      pickingRecordMaterials({ id })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    preview(imageUrl) {
      this.previewVisible = true;
      this.previewImage = imageUrl;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
